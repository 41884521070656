<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">更换成功</div>
    </div>
    <div class="icon"></div>
    <div class="tiShi">更换新手机号成功</div>
    <div class="currentMobile">当前绑定手机号：{{ mobile }}</div>
    <div class="outBtn" @click="complete()">完成</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: this.$route.query.mobile,
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    complete(){
        this.$router.push({path:'/accountManage'})
    }
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.outBtn {
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 88px;
  position: fixed;
  bottom: 32px;
  left: 32px;
}
.icon {
  width: 122px;
  height: 122px;
  background: #17d067;
  opacity: 1;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 228px;
}
.tiShi {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  margin-top: 54px;
}
.currentMobile {
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  text-align: center;
  margin-top: 32px;
}
</style>
